// 首页
export default [
  {
    path: '/home',
    meta: {
      icon: '',
      name: 'home',
      title: '首页',
      code: 'home'
    },
    children: [
      {
        path: '/mediation-center',
        meta: {
          icon: 'iconfont icon-menleiguanliicon',
          iconColor: '#4c7cff',
          name: 'mediation-center',
          title: '调解中心管理',
          code: 'home:mediation'
        },
        children: [
          {
            path: '/list',
            meta: {
              name: 'list',
              title: '调用中心列表',
              code: 'home:mediation:list'
            },
            component: () => import('@/webapp/home/center.vue')
          },
          {
            path: '/audit',
            meta: {
              name: 'audit',
              title: '待审核认证',
              code: 'home:mediation:audit'
            },
            component: () => import('@/webapp/home/audit.vue')
          },
          {
            path: '/audit-record',
            meta: {
              name: 'audit-record',
              title: '审核记录',
              code: 'home:mediation:audit-record'
            },
            component: () => import('@/webapp/home/audit-record.vue')
          },
          {
            path: '/public-validate-record',
            meta: {
              name: 'public-validate-record',
              title: '对公验证记录',
              code: 'home:mediation:public-validate-record'
            },
            component: () => import('@/webapp/home/public-validate-record.vue')
          }
        ]

      },
      {
        path: '/user',
        meta: {
          icon: 'iconfont icon-menleiguanliicon',
          iconColor: '#4c7cff',
          name: 'mediation-center',
          title: '用户管理',
          code: 'home:user'
        },
        component: () => import('@/webapp/home/user.vue')
      }

    ]

  }
]
